<template>
  <div id="app" :class="info.system" class="container">
    <header>
      <button @click="closeAll"></button>
    </header>
    <section class="info">
      <div :class="goodNameClass" class="goodName">
        <span/>
        <p>{{ goodName }}</p>
      </div>
      <p :class="info.currency" class="price">{{ info.price }}</p>
    </section>
    <section class="payMethods">
      <ul class="list">
        <!-- 原生支付 -->
        <li v-show="showNativePay" @click="chooseMethod('nativePay')">
          <div class="left">
            <i :class="info.system.includes('ios') ? 'appleIcon' : 'androidIcon' " class="icon"></i>
            <div>
              <p class="first">{{ nativePayTitle }}</p>
              <p class="last">{{ nativePaySubTitle }}</p>
            </div>
          </div>
          <div class="right">
            <label for="nativePay">
              <input id="nativePay" name="payType" type="radio">
              <i v-show="radio==='nativePay'" class="choosed"></i>
            </label>
          </div>
        </li>
        <li v-show="showWallet" @click="chooseMethod('wallet')">
          <div class="left">
            <img class="wallet" src="../assets/images/wallet_ic.png">
            <div>
              <p class="first">Wallet Pay</p>
              <p class="last">{{ nativePaySubTitle }}</p>
            </div>
          </div>
          <div class="right">
            <!-- <span v-show="showPromotionRate">{{promoteRate}}</span> -->
            <label for="wallet">
              <input id="wallet" name="payType" type="radio">
              <i v-show="radio==='wallet'" class="choosed"></i>
            </label>
          </div>
        </li>
        <!--        paypel-->
        <li v-show="showPaypal" @click="chooseMethod('paypal')">
          <div class="left">
            <img class="payLogo" src="../assets/images/paypal-small@2x.png">
            <div>
              <p class="paypalText first"></p>
              <p class="last">Guaranteed by Paypal</p>
            </div>
          </div>
          <div class="right">
            <span v-show="showRewardCoin">+ {{ rewardCoin }} coins</span>
            <label for="paypal">
              <input id="paypal" name="payType" type="radio">
              <i v-show="radio==='paypal'" class="choosed"></i>
            </label>
          </div>
        </li>
        <!--  payermax      -->
<!--        <li @click="chooseMethod('payermax')">-->
<!--          <div class="left">-->
<!--            <img class="payLogo" src="../assets/images/payermax_logo.png">-->
<!--            <div>-->
<!--              <p class="first">Payermax</p>-->
<!--              <p class="last">Guaranteed by Payermax</p>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="right">-->
<!--            <span></span>-->
<!--            <label for="payermax">-->
<!--              <input id="payermax" name="payType" type="radio">-->
<!--              <i v-show="radio==='payermax'" class="choosed"></i>-->
<!--            </label>-->
<!--          </div>-->
<!--        </li>-->
        <!-- 导量 -->
        <li v-show="showDaoliang" @click="chooseMethod('daoliang')">
          <div class="left">
            <img :src="daoliang.downloadIcon" class="payLogo">
            <div>
              <p class="first">{{ daoliang.promoTitle }}</p>
              <p class="last"></p>
            </div>
          </div>
          <div class="right">
            <label for="daoliang">
              <input id="daoliang" name="payType" type="radio">
              <i v-show="radio==='daoliang'" class="choosed"></i>
            </label>
          </div>
        </li>
      </ul>
    </section>
    <section class="btn">
      <div v-if="canUseCheckout" id="paypalBtn">
        <ym-checkout
            :amount="info.priceNum"
            :currencyCode="info.currency"
            :customProps="payParams"
            :env="env"
            :iap="iap"
            :localPrice="info.price"
            :session="info.session"
            :type="radio"
            :countryCode="info.countryCode"
            :debug="true"
            @finished="onFinished"
            @orderCreated="onOrderCreated"
            @orderHandled="onOrderHandled"
            @cancel="onCancel"
            @error="onError"
        />
      </div>
      <button v-else @click="openCheckout()">{{ btnTitle }}</button>
      <a class="support" @click="openEmail">Support:&nbsp;<span>{{ supportMail }}</span></a>
    </section>
    <transition
        enter-active-class="animated fastest fadeIn"
        leave-active-class="animated fastest fadeOut"
    >
      <!--   弹框   -->
      <my-dialog v-if="showDialog" :msg="dialogMsg" @ok="handelDialogClick"/>
    </transition>
  </div>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import {ref, computed, reactive, unref, defineComponent} from '@vue/composition-api'
import {_get} from "@/utils/axiosInstance"
import lodash_get from 'lodash.get'
import Cookies from 'js-cookie'

import {
  defaultDownloadIcon,
  getCountryCode,
  getNumberOfPrice,
  parseUserLevel,
  paypalAllowedCurrency,
  registerBridge
} from "@/utils";
import {useClipboard} from '@vueuse/core'
import testData from '../test.json'
import MyDialog from '@/components/dialog'
import {makeNativePay, openBrowser, sendFailedMsg, sendSuccessMsg,closeAll as close} from "@/utils/bridge";
//api
const getConfig = productId => _get('/cfg', {productId}).then(res => ({type: 'cfg', res})).catch(() => ({}))
const getUserLevel = session => _get('/cfg/webDliangUserLevel', {session}).then(res => ({
  type: 'level',
  res
})).catch(() => ({}))
const getHref = ()=>{
  const l = window.location
  return `${l.protocol}//${l.host}/#/result4payermax`
}
export default defineComponent({
  name: 'Index',
  components: {MyDialog},
  setup() {

    //data
    const radio = ref('')
    const info = reactive({
      system: '',
      userId: '',
      directOrderLikeMediaId: '',
      directOrderLikeMediaUrl: '',
      isPromotionOrder: false,
      price: '',
      session: '',
      isDirectFollowOrder: false,
      num: '',
      promoteRate: '',
      promId: '',
      appName: '',
      currency: '',
      clientId: '',
      priceNum: 0,
      countryCode: '',
      bundleId: '',
      productName: '',
      goodName: ''
    })
    const showDaoliang = ref(false) // 显示导量
    const daoliang = reactive({
      promoText: '',
      androidStorePackageName: '',
      appURL: ''
    }) //导量信息
    const showNativePay = ref(false) //显示原生支付
    const showWallet = ref(false) //显示钱包
    const showPaypal = ref(false) //显示PayPal
    const supportMail = ref('') // 客服邮箱
    const supportMailText = ref('')
    const goodName = ref('') // 商品标题
    const goodNameClass = ref('') //商品icon classname
    const rewardCoin = ref(0) // 赠送金币
    const showRewardCoin = ref(false) //是否显示
    const showDialog = ref(false)
    const dialogMsg = reactive({
      type:'',
      title:'',
      content: '',
      btnText:''
    })
    const iap = reactive({})
    const nativePaySubTitle = ref('')
    const nativePayTitle = ref('')
    // computed
    const payParams =  computed(() => ({
      payermaxNoRedirect:true,
      userId:info.userId,
      frontCallBackUrl:getHref()
    }))
    const canUseCheckout = computed(() => {
      return ['paypal', 'payermax'].includes(radio.value)
    })
    const btnTitle = computed(() => {
      if (radio.value === 'daoliang') {
        return 'Get VIP App'
      } else if (radio.value === 'nativePay' && info.system.includes('ios')) {
        return 'Apple Pay'
      } else {
        if (info.currency === 'RUB') {
          return info.price + ' RUB'
        }
        return info.price
      }
    })
    // const nativePayTitle = computed(() => )
    const env = computed(() => process.env.VUE_APP_ENV)
    // const iap = computed(() => {
    //   const name = unref(info.goodName)
    //   const baseObj = {
    //     iapId: info.productName,
    //     productId: info.bundleId,
    //     itemType: '',
    //     itemName: info.goodName
    //   }
    //   if (name === 'coins') {
    //     baseObj.itemType = 'coins_money'
    //   } else if (name === 'likes') {
    //     baseObj.itemType = 'like_money'
    //     baseObj.directOrderLikeMediaId = info.directOrderLikeMediaId
    //     baseObj.directOrderLikeMediaUrl = info.directOrderLikeMediaUrl
    //   } else if (name === 'followers') {
    //     baseObj.itemType = 'follow_money'
    //   } else if (name === 'Likes Vip') {
    //     baseObj.itemType = 'like_vip_monthly'
    //   } else if (name === 'Follower Vip') {
    //     baseObj.itemType = 'follow_vip_weekly'
    //   }
    //
    //   return baseObj
    // })

//methods
//jsBridge
// 打开网址
    const openURL = (system,url,type,packageName='') => {
      const openDialog=()=>{
        if(type === 'email'){
          dialogMsg.title='Get Support'
          dialogMsg.type=type
          dialogMsg.content='Please copy our support email and log to clipboard if failed to send us feedback.'
          dialogMsg.btnText='OK'
        }
        if(type === 'daoliang'){
          dialogMsg.title='Get for Faster Speed.'
          dialogMsg.type=type
          dialogMsg.content='A new app to enjoy high-speed delivery of followers & likes. All your COINS and VIP benefits are still available in new app. Url copy succeed. Please paste the link to Safari.'
          dialogMsg.btnText='GET IT NOW'
        }
        showDialog.value = true
      }
      openBrowser(system,url,packageName,openDialog)
    }

//jsBrige end
    const openCheckout = () => {
      const choice = unref(radio)
      if (choice === 'nativePay') {
        makeNativePay(info.system)
      } else if (choice === 'wallet') {
        makeNativePay(info.system)
      } else if (choice === 'daoliang') {
        openURL(info.system,daoliang.appURL,'daoliang',daoliang.androidStorePackageName)
      }
    }
    const closeAll = () => close(info.system);
    const chooseMethod = (name) => {
      radio.value = name
    }
    const openEmail = () => {
      openURL(info.system,unref(supportMailText),'email',daoliang.androidStorePackageName)
    }
    const onOrderCreated = () => {
      if (unref(radio) === 'paypal') {
        $loading('')
      }
    }
    const onFinished = (result, data) => {
      $loading('', 'close')
      if (result === 'success') {
        sendSuccessMsg(info.system,data)
      } else {
        sendFailedMsg(info.system,101)
        $toast('payment failed')
        setTimeout(closeAll, 2500)
      }
    }
    const onOrderHandled = (result, data) => {
      if (result === 'failedWithRetried') {
        $toast('Please contact our service if you have payment issues.', {duration: 10 * 1000})
      }
      if (result === 'redirect' && data && data.payermaxDirectUrl) {
        openURL(info.system,data.payermaxDirectUrl)
      }
    }

    const handelDialogClick = (type) => {
      const source = ref(type==='daoliang'?daoliang.appURL:unref(supportMailText))
      const {copy, copied} = useClipboard({source})
      copy(unref(source))
      if (copied) {
        showDialog.value = false
      }
    }
    const onCancel = (type,data)=>{
      console.log(type,' canceled ',data)
      $loading('', 'close')
    }
    const onError = (type,err)=>{
      console.log(type,' ERROR: ',err)
      $loading('', 'close')
    }
    const configureOrder = window.configureOrderInfo = async (text) => {
      let obj = {}
      try{
        obj = typeof text === 'string' ? JSON.parse(text):text
      }catch(err){
        console.log('the string form App is not a JSON Object')
        return
      }
      const fromApp = {
        'userId': obj.x1pk,
        'system': obj.ixx6.toLowerCase(),
        'directOrderLikeMediaId': obj.ym15,
        'isPromotionOrder': !!parseInt(obj['5krp']),
        'price': obj.p4ce,
        'productName': obj.garg,
        'directOrderLikeMediaUrl': obj.klyl,
        'goodName': obj.n5v6,
        'session': obj.p5tv,
        'isDirectFollowOrder': !!parseInt(obj['1iyp']),
        'num': obj.f4ez,
        'promoteRate': obj.tyre,
        'promId': obj.m9wy,
        'appName': obj.hw3i,
        'currency': obj.inh2 || 'USD',
        'clientId': obj.vzhn || '',
        // 2020-05-25新增
        priceNum: obj.mmes || '',
        countryCode: obj.pgti || getCountryCode(),
        bundleId: obj.zojt || ''
      }
      fromApp.bundleId = fromApp.bundleId === '' ? fromApp.productName.split('.').slice(0, -1).join('.') : fromApp.bundleId
      nativePayTitle.value = fromApp.system.includes('ios') ? 'Apple Pay' : 'Google Play'
      nativePaySubTitle.value = fromApp.system.includes('ios') ? 'Pay within the App Store' : ''
      //goodName
      const isVip = /vip/ig.test(fromApp.goodName)
      if (fromApp.isPromotionOrder && !isVip) {
        goodName.value = `${fromApp.num}  ${fromApp.goodName} Most`
      } else if (isVip) {
        const prefix = fromApp.goodName.toLowerCase().indexOf('like') > -1 ? '30 Days ' : '7 Days '
        goodName.value = prefix + fromApp.goodName
      } else {
        goodName.value = fromApp.num + ' ' + fromApp.goodName
      }

      if (fromApp.goodName === 'Likes Vip') {
        goodNameClass.value = 'likeVip'
      } else if (fromApp.goodName === 'Follower Vip') {
        goodNameClass.value = 'followVip'
      } else {
        goodNameClass.value = fromApp.goodName.toLowerCase()
      }
      if (goodName.value.length > 25) {
        goodNameClass.value += ' smallfont'
      }
      // iap
        const iapObj = {
          iapId: fromApp.productName,
          productId: fromApp.bundleId,
          itemType: '',
          itemName: unref(goodName)
        }
      if(fromApp.promId){
        iapObj.promId = fromApp.promId
      }
      if(!fromApp.promId&&fromApp.isPromotionOrder){
        iapObj.isLuckPack = true
      }
      const name = fromApp.goodName.toLowerCase()
        if (name === 'coins') {
          iapObj.itemType = 'coins_money'
        } else if (name === 'likes') {
          iapObj.itemType = 'like_money'
          iapObj.directOrderLikeMediaId = fromApp.directOrderLikeMediaId
          iapObj.directOrderLikeMediaUrl = fromApp.directOrderLikeMediaUrl
        } else if (name === 'followers') {
          iapObj.itemType = 'follow_money'
        } else if (name === 'likes vip') {
          iapObj.itemType = 'like_vip_monthly'
        } else if (name === 'follower vip') {
          iapObj.itemType = 'follow_vip_weekly'
        }
      Object.assign(iap,iapObj)
      if (fromApp.system.includes('android')) {
        info.isPromotionOrder = obj.xaba
        info.isDirectFollowOrder = obj.xaaa
        const stop = e => {
          e.preventDefault()
          e.stopPropagation()
          return false
        }
        window.addEventListener('paste', stop)
        window.addEventListener('selectstart', stop)
        window.addEventListener('contextmenu', stop)
        if(window.WebViewJavascriptBridge){
          window.WebViewJavascriptBridge.send('disableUserFinishTrue')
        }
      }
      if (fromApp.priceNum === '') {
        fromApp.priceNum = getNumberOfPrice(fromApp.price)
      }
      if (fromApp.currency === 'RUB') {
        fromApp.price = fromApp.priceNum + ''
      }
      console.log('get AppInfo end', fromApp)
      Object.assign(info, fromApp)
      Cookies.set('info',JSON.stringify(fromApp))
      $loading('')
      // 请求接口
      const res = await Promise.all([
        getConfig(fromApp.bundleId),
        getUserLevel(fromApp.session)
      ])
      const cfg = res.find(e => e.type === 'cfg')
      //处理配置项
      if (!cfg.res) {
        daoliang.downloadIcon = defaultDownloadIcon
        if (fromApp.system.includes('android')) {
          daoliang.androidStorePackageName = 'com.android.vending'
          daoliang.appURL = 'https://play.google.com/store/apps/details?id=com.wallet.supersafeso'
        } else {
          daoliang.appURL = 'https://apps.apple.com/app/id1471617012?mt=8'
        }
        daoliang.promoTitle = 'Get Our New App'
        daoliang.promoText = 'Your COINS and VIP benefits are available in new app.Enjoy faster delivery speed.'
        showDaoliang.value = true
      } else {
        showDaoliang.value = !!cfg.res.cfg_dliang_dakai
        daoliang.promoText = cfg.res.cfg_webpay_dliang_desc || 'Your COINS and VIP benefits are available in new app.Enjoy faster delivery speed.'
        daoliang.androidStorePackageName = cfg.androidConfig ? cfg.res.androidConfig.cfg_dliang_marketname : 'com.android.vending'
        showNativePay.value = cfg.res.cfg_webpay_nativepay_show
        showWallet.value = fromApp.system.includes('android') && lodash_get(cfg, 'res.androidConfig.cfg_wallet_enable', 0) === 1
        //paypal是否显示
        if (paypalAllowedCurrency.includes(fromApp.currency.toLowerCase())) {
          showPaypal.value = true
        }
      }
      // 处理导量数据
      const levelData = res.find(e => e.type === 'level')
      if (levelData.res) {
        const userLevel = levelData.res.level
        const mailList = {
          101: 'feedbackwithkelly@gmail.com',
          102: 'feedbackwithkelly@gmail.com',
          201: 'feedbackwithlily@gmail.com',
          202: 'feedbackwithlily@gmail.com',
          203: 'feedbackwithlily@gmail.com',
          204: 'feedbackvipwithemily@gmail.com',
          205: 'feedbackvipwithemily@gmail.com',
          206: 'feedbackvipwithvicky@gmail.com',
          207: 'feedbackvipwithvicky@gmail.com'
        }
        // 用户邮箱以及日志
        supportMail.value = mailList[userLevel] || mailList[101]
        const msg = `session=${fromApp.session}&iapId=${fromApp.productName}&promId=${fromApp.promId || ''}`
        const base64 = window.btoa(msg)
        supportMailText.value = `mailto:${unref(supportMail)}?body=this can help us identify your issue: ${base64}`

        const rewardRatio = isNaN(parseFloat(levelData.res.paypal_coin_rate)) ? 0.1 : parseFloat(levelData.res.paypal_coin_rate)
        let num = parseInt(fromApp.num)
        if (fromApp.isPromotionOrder) {
          num = 600
        }
        rewardCoin.value = Math.round(num * rewardRatio)
        showRewardCoin.value = fromApp.goodName.toLowerCase() === 'coins'
        // 处理用户导量 顺序如下：用户等级导量=>用户等级默认=>cfg中默认导量
        if (cfg.res && userLevel) {
          const userDaoliang = parseUserLevel(cfg.res, userLevel, fromApp.system)
          if (userDaoliang) {
            // 修改导量内容
            daoliang.appURL = userDaoliang.failpy_url
            if (info.system.includes('ios')) {
              daoliang.appURL += `?idfa=${info.clientId}`
            }
            daoliang.androidStorePackageName = userDaoliang.scheme
            daoliang.promoTitle = userDaoliang.product_name

            daoliang.downloadIcon = defaultDownloadIcon
            const img = new Image()
            img.onload = () => {
              daoliang.downloadIcon = userDaoliang.cfg_failpy_icon
            }
            img.src = userDaoliang.cfg_failpy_icon

          }
        }
      }
      // 判断默认选项
      if (unref(showNativePay)) {
        radio.value = 'nativePay'
      } else if (unref(showPaypal)) {
        radio.value = 'paypal'
      } else {
        radio.value = 'payermax'
      }
      $loading('', 'close')
    }
    if(window._env.command === 'production'){
      const timer = setInterval(() =>{
        const data = window.sessionStorage?window.sessionStorage.getItem('orderInfo'):Cookies.get('orderInfo')
        if(data){
          configureOrder(data)
          clearInterval(timer)
        }
      },100)
    }
    return {
      radio,
      info,
      showDaoliang,
      daoliang,
      showNativePay,
      showWallet,
      showPaypal,
      supportMail,
      supportMailText,
      goodName,
      goodNameClass ,
      rewardCoin,
      showRewardCoin,
      showDialog,
      canUseCheckout,
      btnTitle,
      nativePaySubTitle,
      nativePayTitle,
      env,
      iap,
      payParams,
      openCheckout,
      closeAll,
      chooseMethod,
      openEmail,
      onFinished,
      onOrderHandled,
      onOrderCreated,
      handelDialogClick,
      onCancel,
      onError,
      dialogMsg
    }
  },
  mounted () {
    if (window._env.isSandbox && window._env.command === 'development') {
      configureOrderInfo(testData)
    }
  }
})


</script>

<style>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.container header {
  width: 100%;
  height: .8rem;
  display: flex;
  box-sizing: border-box;
  padding-top: .35rem;
  padding-left: .1rem;
}

header button {
  width: .32rem;
  height: .32rem;
  background: url('../assets/images/ico-left-arrow.png') no-repeat center center;
  background-size: .16rem .16rem;
}

.info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: .27rem;
  border-bottom: 1px solid #F4F4F4;
}

.info div {
  display: flex;
  margin-bottom: .09rem;
  align-items: center;
}

.info div img {
  display: block;
  width: .18rem;
  height: .18rem;
  margin-right: .05rem;
}

.info div p {
  height: .27rem;
  font-size: .22rem;
  font-family: PayPalSansSmall-Regular, 'PayPal Sans Small';
  font-weight: 400;
  color: #484848;
  line-height: .27rem;
}

.info .price {
  color: #0C59FE;
  height: .17rem;
  font-size: .14rem;
  font-family: PayPalSansSmall-Regular, 'PayPal Sans Small';
  font-weight: 400;
  line-height: .17rem;
}

.goodName span {
  display: block;
  width: .18rem;
  height: .18rem;
  margin-right: .05rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.coins span {
  background-image: url('../assets/images/coins_buy_ic@2x.png');
}

.likes span {
  background-image: url('../assets/images/like_buy_ic@2x.png');
}

.followers span {
  background-image: url('../assets/images/follower_buy_ic@2x.png');
}

.likeVip span {
  background-image: url('../assets/images/follow_vip_ic@2x.png');
}

.followVip span {
  background-image: url('../assets/images/like_vip_ic@2x.png');
}

.container .payMethods {
  width: 100%;
  flex: 1;
  overflow-y: scroll;
  padding-top: .32rem;
  margin-bottom: .2rem;
}

.payMethods ul {
  width: 100%;
  box-sizing: border-box;
  padding-left: .27rem;
  padding-right: .24rem;
}

.goodName {
  width: fit-content;
  height: .2rem;
  font-size: .18rem;
  font-weight: normal;
  color: #000;
  line-height: .21rem;
}

.list li {
  display: flex;
  justify-content: space-between;
  margin-bottom: .3rem;
  cursor: pointer;
}

.list .left {
  display: flex;
  align-items: center;
}

.list .left img {
  margin-right: .17rem;
  width: .24rem;
  height: .24rem;
}

/* .list .left div{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
} */
.list .left .first {
  height: .2rem;
  font-size: 16px;
  font-family: PayPalSansSmall-Regular, 'PayPal Sans Small';
  font-weight: 400;
  color: #1B1B1B;
  line-height: .2rem;
  margin-bottom: .03rem;
}

.list .left .last {
  height: .14rem;
  font-size: .1rem;
  font-family: PayPalSansSmall-Regular, 'PayPal Sans Small';
  font-weight: 400;
  color: #8F8F8F;
  line-height: .14rem;
  background: url('../assets/images/safety_ic@2x.png') no-repeat left bottom;
  background-position-y: .02rem;
  background-size: .1rem;
  text-indent: .14rem;
  /* transform: scale(.8); */
  text-transform: lowercase;
  /* margin-left: -.16rem; */
}

.payMethods .list .left .nosub {
  margin-bottom: 0;
}

.list .icon {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  margin-right: 17px;
}

.list .androidIcon {
  width: .24rem;
  height: .24rem;
  background-image: url('../assets/images/google_play.png');
}

.list .appleIcon {
  width: .20rem;
  height: .24rem;
  background-image: url('../assets/images/apple_ic.png');
}

.list .payLogo {
  width: .24rem;
  height: .24rem;
}

.list .paypalText {
  background: url('../assets/images/paypal_text_ic@2x.png') no-repeat left center;
  background-size: contain;
}

.list .right {
  display: flex;
  align-items: center;
}

.list .right span {
  display: block;
  height: .15rem;
  line-height: .15rem;
  font-size: .14rem;
  color: #FC6076;
  margin-right: .08rem;
  white-space: nowrap;
  font-family: PayPalSansSmall-Regular, 'PayPal Sans Small';
}

.list .right label {
  display: block;
  width: .14rem;
  height: .14rem;
  position: relative;
  background: transparent;
}

.list .right .choosed {
  display: block;
  width: .16rem;
  height: .16rem;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  background: url('../assets/images/pay_select_ic@2x.png') no-repeat left top;
  background-size: contain;
}

.list .right .choosed::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;

}

.list .right input {
  display: block;
  width: .16rem;
  height: .16rem;
  border: 1px solid #AFAFAF;
  border-radius: 50%;
  background-color: transparent;
}

.container .btn {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-left: .21rem;
  padding-right: .24rem;
  margin-bottom: .15rem;
}

.container .btn button {
  width: 100%;
  height: .48rem;
  /* background:linear-gradient(45deg,rgba(22,87,208,1) 0%,rgba(113,73,255,1) 100%); */
  /* background:linear-gradient(88deg,rgba(255,166,34,1) 0%,rgba(255,0,97,1) 49%,rgba(255,0,166,1) 100%); */
  /* box-shadow:0px .06rem .1rem 0px rgba(94,76,245,0.7); */
  background: linear-gradient(328deg, rgba(12, 89, 254, 1) 0%, rgba(44, 120, 252, 1) 100%);
  box-shadow: 0 .18rem .18rem 0 rgba(95, 145, 255, 0.26);
  border-radius: .08rem;
  /* margin: 0 auto; */
  font-size: .18rem;
  font-family: PayPalSansSmall-Regular, 'PayPal Sans Small';
  font-weight: 400;
  color: #fff;
  /* line-height:.48rem; */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paySelect .gc-embed-button span {
  font-size: .18rem;
  font-family: Arial-Black, Arial;
  font-weight: 900;
}

.container .bottom {
  position: absolute;
  left: 50%;
  bottom: .3rem;
  transform: translateX(-50%);
}

.container .cardLogo {
  display: block;

  margin: 0 auto;
  margin-top: .45rem;
}

.container .daoliang .left p {
  white-space: normal;
}

.container .endbox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container .endbox img {
  width: 1.14rem;
  height: .14rem;
}

.container .support {
  display: block;
  height: .34rem;
  font-size: .12rem;
  font-family: 'PayPal Sans Small';
  font-weight: 400;
  color: #6E7A91;
  line-height: .17rem;
  background-color: transparent;
  /* position: absolute;
  left:50%;
  bottom: .2rem;
  transform: translateX(-50%); */
  margin: .3rem auto 0;
  /* margin-top:-.1rem; */
}

.container .support span {
  color: #1D6BF5;
}

.RUB {
  overflow: hidden;
  display: flex;
  align-items: center;
}

.RUB::after {
  content: '';
  display: block;
  width: .15rem;
  height: .15rem;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAAXNSR0IArs4c6QAAA0BJREFUWAntV01oU0EQnnkvtk0TELWiKLa2Cv6ARSx4EQSv3sRWEHoSvKXVg/+nHkTwUCwkVbx5kUrx2osH8SJ6iaeCINr8WERaKKVIXmzydpwt3WTfJs17jW2TQxYe87u7X2ZnZycATT6wHny9t5yLrite+M4lWAXELPtliCBtWfy1YTI1Hs74zl13CAV11P0EYZQ3PK3rNuSJziqbEAAiD253LDeFIftJZqL9q7JtRK2NDNunJ5uIhkXRnWWg00fv0sFae20NQESBgHPmx8c7z1+xKgAii4EOCcd5O/CSdlX1YWVdR2wuhkAr2cnIMVMv5aFpsr98dg4Vi3hcCLoJBNcAyFa+DPLCwqzzjOWY0um0rkvSM5q/LFx3Ri2ECMvZRGSPkmvRnpjTK4DeANF53c+yrOFMPPxa10l+a47YXLWGnEmEU+0IVzkyC7qbEOKeLit+xwHKjb/HO+ctC28rEOu0/8hIviJNGgJwDZCNnwyAYJN7xtQ1DGB6Ipzm3F3RAXEpOKDLkm8YQLk5Af6WVA0uU1HFK9owgMdHqJ1B9CkgkhKi5+JIXcMAFu3CKS41Zh3+JUHpo2EAqVgY1IEAYG7/3o6Ki9MQgD2jziUB+FAHyBdmJjmGOV0n+R0FyM8ado/kBkmIKT7e8t4IS3xB7pvgpGzmQDWfunXyHU5+zB/mNqtXkDjB4GJ8db21jhsNQOt6Jt6RqrZRoLe4/w5FlvPOklqAZOSNBEfEVWVXlP1Cnkgpg0Y5cg+yk51PNZWHDRxBPp42z0xD8LMb7tyF4Q8uK4+z8fAr06bL5TzQtdvIS2A2Wjf6usInf/qAkzACRXAlCi7m4Z2Gu4tb/nMlmZtS7gnfl2TFEDqcDDK3UiCsObBwrm9f27cPY1hMKx8fGigHzTX+px801/KTd/yI/QCZ9hZAMyKblZs+goEuycAYdS4u5kqdBtcvWYAj5WggyX92ZbmS47f2USYReV5pqa0JVGYKfwD5VdhdWoprjHeQ1+41rkncnMr+b9Oj6Y84UATdKBTAwfHSz0fuhImuKJlfh78c1ISSq9GQjclqej9doBw0F2kVai0iTZ+DLYDaadXFtiJYV9hak7YwAv8AbFINfgGz8MsAAAAASUVORK5CYII=');
  background-repeat: no-repeat;
  background-size: contain;
}


</style>
